
// Converted Variables


// Custom Media Query Variables


/*

  STYLES

  Add custom styles here.

*/


body {
    font-family: $sans-serif;
    line-height: $line-height-copy;
    font-size: $font-size-6;
    color: $near-black;
    background-color: $background-color;
}

@media #{$breakpoint-medium} {
    body {
        font-size: $font-size-5;
    }
}

a {
    color: $near-black;
    text-underline-offset: 1px;
    transition: background-color .1s;
    border-radius: 5px;
    text-decoration-color: $light-red;
    text-decoration-thickness: 2px;
}

a:hover {
    text-decoration: none;
    background-color: $light-yellow;
    box-shadow: 0 0 4px 2px $light-yellow;
}
a:focus {
    outline: 2px dotted;
    z-index: 1;
}

// a:active,
// a:focus {
//     outline: none;
//     background-color: $yellow;
//     color: $dark-gray;
//     box-shadow: 0 -2px $yellow, 0 1px $dark-gray;
//     text-decoration: none;
// }

hr {
    height: 64px;
    border: 0;
    position: relative;
}

hr::before {
    content: "";
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 4px);
    height: 4px;
    width: 80px;
    background: repeating-linear-gradient(to right, $dark-gray, $dark-gray 16px, white 16px, white 32px);
    border: 1px solid;
}



/* Eliminate top margin
https://csswizardry.com/2012/06/single-direction-margin-declarations/
----------------------------------------------------------------------------- */
h1,h2,h3,h4,h5,h6,hgroup,
ul,ol,dd,
p,figure,
pre,table,fieldset,hr {
    margin-top: 0;
    margin-bottom: 1rem;
}

.toc {
    white-space: nowrap;
    overflow: hidden;
    padding: 4px;
    margin: -4px;
}

@media #{$breakpoint-large} {
    .toc::after {
        content: " ...........................................................................................................................................................";
        font-size: 11px;
        letter-spacing: .1rem;
        padding-left: 4px;
    }
}

.post-content h2 {
    font-size: $font-size-5;
    font-weight: 400;
    border-bottom: 1px solid;
    padding-bottom: 2px;
}

@media #{$breakpoint-medium} {
    .post-content h2 {
        font-size: $font-size-4;
    }
}

.post-content img + em {
    font-size: $font-size-7;
    display: block;
    text-align: center;
}

.post-content h3 {
    font-size: inherit;
    font-style: italic;
    font-weight: normal;
}

.post-content code {
    font-size: .9em;
}

.post-content blockquote {
    font-size: $font-size-5;
    padding-left: 2rem;
    margin-left: 0;
    position: relative;
}

.post-content table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
}

.post-content table td {
    border-top: 1px solid $black-10;
}


.post-content .highlight {
    border: 1px solid;
    padding: 16px;
    position: relative;
    margin-top: 1.25rem;
}

.post-content .highlight::before {
    content: "✻";
    position: absolute;
    right: 8px;
    top: -14px;
    background-color: $background-color;
    padding: 0 8px;
}

a.footnote {
    text-decoration: none;
    margin-left: 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    border: 1px solid;
    width: 20px;
    height: 16px;
    text-align: center;
    border-radius: 999px;
    font-size: 12px;
}

.footnotes {
    font-size: $font-size-7;
}

.footnotes ol {
    padding-top: 1em;
    padding-left: 1em;
    margin-bottom: 0;
}

.footnotes p {
    margin-bottom: .25em;
}

.home-excerpt p img {
    mix-blend-mode: multiply;
}