
// Converted Variables


// Custom Media Query Variables


/*

   LINKS
   Docs: http://tachyons.io/docs/elements/links/

*/

.link {
  text-decoration: none;
}
