// ! TACHYONS v4.9.0 | http://tachyons.io

//
//
//      ________            ______
//      ___  __/_____ _________  /______  ______________________
//      __  /  _  __ `/  ___/_  __ \_  / / /  __ \_  __ \_  ___/
//      _  /   / /_/ // /__ _  / / /  /_/ // /_/ /  / / /(__  )
//      /_/    \__,_/ \___/ /_/ /_/_\__, / \____//_/ /_//____/
//                                 /____/
//
//    TABLE OF CONTENTS
//
//    1. External Library Includes
//       - Normalize.css | http://normalize.css.github.io
//    2. Tachyons Modules
//    3. Variables
//       - Media Queries
//       - Colors
//    4. Debugging
//       - Debug all
//       - Debug children
//
//


 //  External Library Includes
 @import 'tachyons/normalize';


//  Variables
//  Importing here will allow you to override any variables in the modules

@import 'tachyons/variables';

//  Debugging
@import 'tachyons/debug-children';
@import 'tachyons/debug-grid';

//  Uncomment out the line below to help debug layout issues
//  @import 'tachyons/debug';

 //  Modules
 @import 'tachyons/box-sizing';
 @import 'tachyons/aspect-ratios';
 @import 'tachyons/images';
 @import 'tachyons/background-size';
 @import 'tachyons/background-position';
 @import 'tachyons/outlines';
 @import 'tachyons/borders';
 @import 'tachyons/border-colors';
 @import 'tachyons/border-radius';
 @import 'tachyons/border-style';
 @import 'tachyons/border-widths';
 @import 'tachyons/box-shadow';
 @import 'tachyons/code';
 @import 'tachyons/coordinates';
 @import 'tachyons/clears';
 @import 'tachyons/flexbox';
 @import 'tachyons/display';
 @import 'tachyons/floats';
 @import 'tachyons/font-family';
 @import 'tachyons/font-style';
 @import 'tachyons/font-weight';
 @import 'tachyons/forms';
 @import 'tachyons/heights';
 @import 'tachyons/letter-spacing';
 @import 'tachyons/line-height';
 @import 'tachyons/links';
 @import 'tachyons/lists';
 @import 'tachyons/max-widths';
 @import 'tachyons/widths';
 @import 'tachyons/overflow';
 @import 'tachyons/position';
 @import 'tachyons/opacity';
 @import 'tachyons/rotations';
 @import 'tachyons/skins';
 @import 'tachyons/skins-pseudo';
 @import 'tachyons/spacing';
 @import 'tachyons/negative-margins';
 @import 'tachyons/tables';
 @import 'tachyons/text-decoration';
 @import 'tachyons/text-align';
 @import 'tachyons/text-transform';
 @import 'tachyons/type-scale';
 @import 'tachyons/typography';
 @import 'tachyons/utilities';
 @import 'tachyons/visibility';
 @import 'tachyons/white-space';
 @import 'tachyons/vertical-align';
 @import 'tachyons/hovers';
 @import 'tachyons/z-index';
 @import 'tachyons/nested';
 @import 'tachyons/styles';
