
// Converted Variables

$sans-serif: "public sans", sans-serif;
$serif: "source serif pro", georgia, serif !default;
$code: Menlo, Consolas, monospace !default;
$font-size-headline: 4rem !default;
$font-size-subheadline: 3rem !default;
$font-size-1: 2rem !default;
$font-size-2: 1.625rem !default;
$font-size-3: 1.375rem !default;
$font-size-4: 1.25rem !default;
$font-size-5: 1.125rem !default;
$font-size-6: 1rem !default;
$font-size-7: .875rem !default;
$letter-spacing-tight: -.05em !default;
$letter-spacing-1: .075em !default;
$letter-spacing-2: .15em !default;
$line-height-solid: 1 !default;
$line-height-title: 1.25 !default;
$line-height-copy: 1.5 !default;
$measure: 30em !default;
$measure-narrow: 20em !default;
$measure-wide: 36em !default;
$spacing-none: 0 !default;
$spacing-extra-small: .25rem !default;
$spacing-small: .5rem !default;
$spacing-medium: 1rem !default;
$spacing-large: 2rem !default;
$spacing-extra-large: 4rem !default;
$spacing-extra-extra-large: 8rem !default;
$spacing-extra-extra-extra-large: 16rem !default;
$spacing-copy-separator: 1.5em !default;
$height-1: 1rem !default;
$height-2: 2rem !default;
$height-3: 4rem !default;
$height-4: 8rem !default;
$height-5: 16rem !default;
$width-1: 1rem !default;
$width-2: 2rem !default;
$width-3: 4rem !default;
$width-4: 8rem !default;
$width-5: 16rem !default;
$max-width-1: 1rem !default;
$max-width-2: 2rem !default;
$max-width-3: 4rem !default;
$max-width-4: 8rem !default;
$max-width-5: 16rem !default;
$max-width-6: 32rem !default;
$max-width-7: 48rem !default;
$max-width-8: 64rem !default;
$max-width-9: 96rem !default;
$border-radius-none: 0 !default;
$border-radius-1: .125rem !default;
$border-radius-2: .25rem !default;
$border-radius-3: .5rem !default;
$border-radius-4: 1rem !default;
$border-radius-circle: 100% !default;
$border-radius-pill: 9999px !default;
$border-width-none: 0 !default;
$border-width-1: .125rem !default;
$border-width-2: .25rem !default;
$border-width-3: .5rem !default;
$border-width-4: 1rem !default;
$border-width-5: 2rem !default;
$box-shadow-1: 0 1px 3px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
$box-shadow-2: 0px 0px 0px 1px rgba(0,0,0,0.1), 0px 10px 30px 0 rgba(0,0,0,0.5) !default;
$box-shadow-3: 2px 2px 4px 2px rgba( 0, 0, 0, 0.2 ) !default;
$box-shadow-4: 2px 2px 8px 0px rgba( 0, 0, 0, 0.2 ) !default;
$box-shadow-5: 4px 4px 8px 0px rgba( 0, 0, 0, 0.2 ) !default;
$black: #000 !default;
$near-black: #111 !default;
$dark-gray: #333 !default;
$mid-gray: #454545 !default;
$gray: #666 !default;
$silver: #999 !default;
$light-silver: #aaa !default;
$moon-gray: #ccc !default;
$light-gray: #e5e8e8 !default;
$near-white: #f2f6f6 !default;
$white: #fff !default;
$transparent: transparent !default;
$black-90: rgba(0,0,0,.9) !default;
$black-80: rgba(0,0,0,.8) !default;
$black-70: rgba(0,0,0,.7) !default;
$black-60: rgba(0,0,0,.6) !default;
$black-50: rgba(0,0,0,.5) !default;
$black-40: rgba(0,0,0,.4) !default;
$black-30: rgba(0,0,0,.3) !default;
$black-20: rgba(0,0,0,.2) !default;
$black-10: rgba(0,0,0,.1) !default;
$black-05: rgba(0,0,0,.05) !default;
$black-025: rgba(0,0,0,.025) !default;
$black-0125: rgba(0,0,0,.0125) !default;
$white-90: rgba(255,255,255,.9) !default;
$white-80: rgba(255,255,255,.8) !default;
$white-70: rgba(255,255,255,.7) !default;
$white-60: rgba(255,255,255,.6) !default;
$white-50: rgba(255,255,255,.5) !default;
$white-40: rgba(255,255,255,.4) !default;
$white-30: rgba(255,255,255,.3) !default;
$white-20: rgba(255,255,255,.2) !default;
$white-10: rgba(255,255,255,.1) !default;
$white-05: rgba(255,255,255,.05) !default;
$white-025: rgba(255,255,255,.025) !default;
$white-0125: rgba(255,255,255,.0125) !default;
$dark-red: #94001f !default;
$red: #b50026 !default;
$light-red: #ec6a6a !default;
$orange: #ff6300 !default;
$gold: #ffb700 !default;
$yellow: #ffe34e !default;
$light-yellow: #fff9ae !default;
$purple: #5e2ca5 !default;
$light-purple: #a463f2 !default;
$dark-pink: #d5008f !default;
$hot-pink: #ff41b4 !default;
$pink: #ff80cc !default;
$light-pink: #ffa3d7 !default;
$dark-green: #05613b !default;
$green: #00a569 !default;
$light-green: #9eebcf !default;
$navy: #001b44 !default;
$dark-blue: #000477 !default;
$blue: #090fc5 !default;
$light-blue: #96ccff !default;
$lightest-blue: #cdecff !default;
$washed-blue: #f6fffe !default;
$washed-green: #e8fdf5 !default;
$washed-yellow: #fffef6 !default;
$washed-red: #ffe6e4 !default;

$link-color: $red;
$background-color: $washed-yellow;

// Custom Media Query Variables

$breakpoint-not-small: 'screen and (min-width: 30em)' !default;
$breakpoint-medium: 'screen and (min-width: 45em)' !default;
$breakpoint-large: 'screen and (min-width: 60em)' !default;

/*

    VARIABLES

*/
